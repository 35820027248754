import "../styles/hero.css";
import '../styles/navbar.css';
import { Fade } from 'react-awesome-reveal';
import { keyframes } from "@emotion/react";
import { Reveal } from "react-awesome-reveal";

export default function Hero(){

const customAnimationTittle = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
    
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

function CustomAnimationTittle({ children }) {
  return <Reveal keyframes={customAnimationTittle} delay={200} duration={2000} triggerOnce>{children}</Reveal>;
}

const customAnimationImg = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
    
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

function CustomAnimationImg({ children }) {
  return <Reveal keyframes={customAnimationImg} delay={200} duration={2000} triggerOnce>{children}</Reveal>;
}

    return(
        <section className="hero">
            <div id="home"></div>
            <div className="hero-container">
                <span className="signboard">Front-End Developer</span>
                
                <div className="tittle">
                <CustomAnimationTittle>
                    <h1>Hello, I'm</h1>
                    <h1 className="name">Javier Fernández</h1>
                    <h4>I design and code beautifully simple things <br></br> and I love what I do.</h4>
                </CustomAnimationTittle>
                </div>

                
                <div className="links">
                <Fade cascade damping={.2} delay={400} direction={'left'} triggerOnce  >
                    <a className="lets-contact" href="#contact">LETS CHAT!</a>
                    <a className="cv" href="../img/CV.pdf" target="_blank">Download CV</a>
                </Fade>
                </div>
                
            </div>
            
            <div className="img-container">
            <CustomAnimationImg>
                <img className="hero-img" src="../img/hero-img.png" alt="" />
            </CustomAnimationImg>
            </div>
            
            
        </section>
    )
}