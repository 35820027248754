import NavBar from './components/navbar';
import Hero from './components/hero';
import About from './components/about';
import Portfolio from './components/portfolio';
import Contact from './components/contact';
import Footer from './components/footer';

import './App.css';

function App() {

  return (
    <div className="App">
      <NavBar />
      <Hero />
      <About />
      <Portfolio />
      <Contact />
      <Footer />
      <script src="js/scrollreveal.min.js"></script>
    </div>
  );
}

export default App;
