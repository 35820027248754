import "../styles/footer.css";
import '../styles/navbar.css';

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-social">
                <a href='https://www.linkedin.com/in/javier-fernandezlopez/' target="_blank"> <img src="../img/linkedin.svg" alt="" /></a>
                <a href='https://github.com/javierfernanlopez' target="_blank"><img src="../img/github.svg" alt="" /></a>
            </div>
            <div className="footer-text">
                <p>Copyright &copy; 2023 by Javier Fernández | All Rights Reserved</p>
            </div>
        </footer>
    )
}