import "../styles/about.css"
import '../styles/navbar.css';


export default function About(){


    return(
        <section className="about">
        <div id="about"></div>
        <div className="about-container"></div>
            <div className="about-img">
                <img src="../img/Imagen3.png" alt="" />
            </div>
            <div className="about-content">
            <h2 className="heading">About <span>Me</span></h2>
                <h3>Front-End Developer</h3>
                <p>I'm an engineer but I want to be a developer. After finishing my degree and working for a few years in the engineering sector I decided to change direction and dedicate myself to what has been my passion: IT.
                    Therefore, I have been training in frontend development for a couple of months doing different courses self-taught.
                    I'm curious, active, always updated in new technologies, eager to grow and with an analytical and problem solving vision that I have acquired during my previous work experiences. </p>
                    <a href="https://www.linkedin.com/in/javier-fernandezlopez/" className="read-more" target="_blank">Read more</a>    
            </div>
        </section>
    )
}
