import React from 'react';
import '../styles/navbar.css';
import { Link } from 'react-scroll';


export default function NavBar() {

    function toggleMenu(){
        document.getElementById('menu-icon').classList.toggle('bx-x');
        document.querySelector('.navbar-menu').classList.toggle('active');
        document.querySelector('.navbar-social').classList.toggle('active');
        document.querySelector('.contact-me').classList.toggle('active');
    }
    function closeMenu(){
    document.getElementById('menu-icon').classList.remove('bx-x');
    document.querySelector('.navbar-menu').classList.remove('active');
    document.querySelector('.navbar-social').classList.remove('active');
    document.querySelector('.contact-me').classList.remove('active');
    }

    

    return (
            <div className="navbar-container">
                <div className="navbar-logo">
                    <a href="/"><span className='firstname'>Javier</span><span className='lastname'>Fernández</span></a>
                </div>
                <i className='bx bx-menu' id='menu-icon' onClick={toggleMenu}></i>
                <div className="navbar-menu">
                    <ul>
                        <li>
                        <Link activeClass="active" onClick={closeMenu} spy href="#home" >
                            Home
                        </Link>
                        </li>
                        <li>
                        <Link activeClass="active" onClick={closeMenu} spy href="#about">
                            About
                        </Link>
                        </li>
                        <li>
                        <Link activeClass="active" onClick={closeMenu} spy href="#portfolio">
                            Projects
                        </Link>
                        </li>
                        <li>
                        <Link activeClass="active" onClick={closeMenu} spy href="#contact">
                            Contact
                        </Link>
                        </li>
                    </ul>
                    
                </div>
                <div className="navbar-social">
                        <a href='https://www.linkedin.com/in/javier-fernandezlopez/' target="_blank"> <img src="../img/linkedin.svg" alt="" /> LinkedIn</a>
                        <a href='https://github.com/javierfernanlopez' target="_blank"><img src="../img/github.svg" alt="" /> Github</a>
                    </div>
            </div>
    )
}
