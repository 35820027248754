import "../styles/contact.css";
import '../styles/navbar.css';
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';

export default function Contact() {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_i7slz95', 
      'template_pe9mzgy', 
      form.current, 
      'b96HZXgdvZmLvm2QG')
      .then(alert("Thank you for your message!"))
      ;e.target.reset();
  };
    const form = useRef();
    
    return (
        <section className="contact-component">
        <div id="contact"></div>
            <h2 className="contact-heading">Contact <span>Me!</span></h2>
            <form id="contact-form" ref={form} onSubmit={sendEmail} name="contact-form" >
            <div className="input-box">
                    <input type="text"  placeholder="Full Name" id="name" name="name" required/>
                    <input type="email"  placeholder="Email Address" id="email" name="email" required/>
                </div>
                <div className="input-box">
                    <input type="text"  placeholder="Mobile Number" id="phone" name="phone"/>
                    <input type="text"  placeholder="Email Subject" id="subject" name="subject"/>
                </div>
                <textarea name="message"  id="message" cols="20" rows="5" placeholder="Your Message"></textarea>
                <input type="submit" value="Send message" className="message-btn" />
            </form>
        </section>
    )
}