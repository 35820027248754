import "../styles/portfolio.css";
import '../styles/navbar.css';


export default function Portfolio(){

    return(
        <section className="portfolio">
        <div id="portfolio"></div>

            <h2 className="portfolio-heading">Latest <span>Projects</span></h2>
            <div className="portfolio-container">
                <div className="portfolio-box">
                    <img src="../img/portfolio/portfolio-meme.png" alt="" />
                    <div className="portfolio-layer">
                        <h4>Meme Generator</h4>
                        <div className="portfolio-links">
                            <a href="https://supermemegenerator.netlify.app/" target="_blank"><i class='bx bx-link-external'></i></a>
                            <a href="https://github.com/javierfernanlopez/meme-generator" target="_blank"><i class='bx bx-code-alt'></i></a>
                        </div>
                    </div> 
                </div>
                <div className="portfolio-box">
                    <img src="../img/portfolio/sketch.PNG" alt="" />
                    <div className="portfolio-layer">
                    <h4>Etch a sketch</h4>
                     <div className="portfolio-links">
                        <a href="https://javierfernanlopez.github.io/etch-a-sketch/" target="_blank"><i class='bx bx-link-external'></i></a>
                        <a href="https://github.com/javierfernanlopez/etch-a-sketch" target="_blank"><i class='bx bx-code-alt'></i></a>
                     </div>
                    </div>
                    
                </div>
                <div className="portfolio-box">
                    <img src="../img/portfolio/tenzies.PNG" alt="" />
                    <div className="portfolio-layer">
                    <h4>Tenzies Game</h4>
                     <div className="portfolio-links">
                        <a href="https://tenziesbyjfl.netlify.app" target="_blank"><i class='bx bx-link-external'></i></a>
                        <a href="https://github.com/javierfernanlopez/tenzies-game" target="_blank"><i class='bx bx-code-alt'></i></a>
                     </div>
                    </div>
                    
                </div>
                <div className="portfolio-box">
                    <img src="../img/portfolio/real-state-web.PNG" alt="" />
                    <div className="portfolio-layer">
                    <h4>Real State Web</h4>
                    <div className="portfolio-links">
                        <a href="https://javierfernanlopez.github.io/real-state-web/" target="_blank"><i class='bx bx-link-external'></i></a>
                        <a href="https://github.com/javierfernanlopez/real-state-web" target="_blank"><i class='bx bx-code-alt'></i></a>
                     </div>
                    </div>
                    
                </div>
                <div className="portfolio-box">
                    <img src="../img/portfolio/calculator.PNG" alt="" />
                    <div className="portfolio-layer">
                    <h4>Calculator</h4>
                     <div className="portfolio-links">
                        <a href="https://javierfernanlopez.github.io/calculator/" target="_blank"><i class='bx bx-link-external'></i></a>
                        <a href="https://github.com/javierfernanlopez/calculator" target="_blank"><i class='bx bx-code-alt'></i></a>
                     </div>
                    </div>
                    
                </div>
                <div className="portfolio-box">
                    <img src="../img/portfolio/airbnb.PNG" alt="" />
                    <div className="portfolio-layer">
                    <h4>Airbnb Clone</h4>
                     <div className="portfolio-links">
                        <a href="https://airbnb-clone-by-jfl.netlify.app/" target="_blank"><i class='bx bx-link-external'></i></a>
                        <a href="https://github.com/javierfernanlopez/airbnb-clone" target="_blank"><i class='bx bx-code-alt'></i></a>
                     </div>
                    </div>
                    
                </div>
            </div>
        </section>
    )
}